import { NavLink } from "react-router-dom";
import { BurgerBoughie } from "react-burger-icons";
import "./navbar.css";
import { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Sidebar } from "./Sidebar";
import application from "../FILEJSON/other pages and includes/applications.json";
import portfolio from "../FILEJSON/portfolio/portfolio.json";
import service from "../FILEJSON/services/services.json";
import home from "../FILEJSON/home/home.json";
import getStarted from "../FILEJSON/other pages and includes/Getstarted.json";
import clientlogin from "../FILEJSON/other pages and includes/Login.json";
import loginIcon from "../../Component/FILEJSON/navbar/loginiconnavbar.json";
import Lottie from "lottie-react";
import { GetStartedbtn } from "../../Pages/Application/Getstartedbtn";
import VideoContainer from "../Video-Container/VideoContainer";
import { useNavigate } from "react-router-dom";

const data = [
  { id: 1, name: "Home", moverto: "/", animationData: home },
  { id: 2, name: "Services", moverto: "/services", animationData: service },
  { id: 3, name: "Portfolio", moverto: "/portfolio", animationData: portfolio },
  {
    id: 4,
    name: "Solutions",
    moverto: "/solutions",
    animationData: application,
  },
];

export default function Navbar() {
  const [isClosed, setIsClosed] = useState(false);
  const containerRef = useRef(null);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [isHover, setisHover] = useState(false);
  const [isHover1, setisHover1] = useState(false);
  const [logoopen, setLogoopen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  //new code
  const [showVideo, setShowVideo] = useState(false);
  const [linkToLaunch, setLinkToLaunch] = useState("");
  const [isRedirecting, setIsRedirecting] = useState(false); // State to track redirection process

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 500);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleGetStartedClick = (link) => {
    setShowVideo(true);
    setLinkToLaunch(link);
    setIsRedirecting(true); // Set redirection flag
  };

  const handleVideoEnd = () => {
    setShowVideo(false);
    window.open(linkToLaunch, "_self");
  };

  useEffect(() => {
    if (isRedirecting) {
      window.open(linkToLaunch, "_self");
    }
  }, [isRedirecting, linkToLaunch]);

  // end new code

  const handleMouseEnter = (id) => {
    setHoveredIcon(id);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const Togglelogo = () => {
    setLogoopen(!logoopen);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <>
      {showVideo && <VideoContainer onVideoEnd={handleVideoEnd} />}
      <nav
        className="navbar navbar-expand-lg navbar-light bg-glass"
        ref={containerRef}
      >
        <div className="container-fluid p-0">
          <Sidebar />
          <NavLink className="navbar-brand" to="/">
            <div
              className="logo-container"
              onClick={Togglelogo}
              onMouseEnter={() => setLogoopen(true)}
              onMouseLeave={() => setLogoopen(false)}
            >
              <img src="/logoicon.png" alt="logo" />
              {!isMobile && logoopen === true && (
                <h3>
                  &nbsp;DA&nbsp;TECH
                  <br />
                  &nbsp;<span>IDEA . </span>
                  <span>DESIGN . </span>
                  <span>CODE . </span>
                  <span>MARKETING</span>
                  &nbsp;
                </h3>
              )}
            </div>
          </NavLink>
          <div className="d-flex d-lg-none justify-content-between align-items-center">
            <div className="mobiledisplay navbar-login-signup">
              <div
                onClick={() =>
                  handleGetStartedClick("https://hrm.datechmarketing.com/login")
                }
                className="btn p-2 px-1"
                style={{
                  backgroundColor: "rgba(249, 252, 253, 0.23)",
                  color: "#fff",
                  width: "8rem",
                }}
                onMouseEnter={() => setisHover1(true)}
                onMouseLeave={() => setisHover1(false)}
              >
                <div className="d-flex">
                  <Lottie
                    animationData={loginIcon}
                    loop={isHover1}
                    isStopped={!isHover1}
                    autoplay={isHover1}
                    style={{ width: 40, height: 40 }}
                  />
                  <p
                    className="mx-1 m-2"
                    style={{ fontFamily: "CFNotche-Bold" }}
                  >
                    Login
                  </p>
                </div>
              </div>

              {/* <NavLink
                to="https://hrm.thedatech.com/client-signup"
                className="btn"
                style={{ border: "black" }}
              >
                <div className="d-flex">
                  <Lottie
                    animationData={getStarted}
                    loop={!isHover}
                    isStopped={!isHover}
                    autoplay={isHover}
                    style={{ width: 40, height: 40 }}
                  />
                  <p className="mt-2" style={{ fontFamily: "CFNotche-Bold" }}>
                    Register
                  </p>
                </div>
              </NavLink> */}
              <div
                onClick={() =>
                  handleGetStartedClick(
                    "https://hrm.datechmarketing.com/client-signup"
                  )
                }
                className="btn m-0"
                onMouseEnter={() => setisHover(true)}
                onMouseLeave={() => setisHover(false)}
                style={{ border: "black", height: "3.7rem" }}
              >
                <div className="d-flex">
                  <Lottie
                    animationData={getStarted}
                    loop={!isHover}
                    isStopped={!isHover}
                    autoplay={isHover}
                    style={{ width: 70, height: 35 }}
                  />
                  <p className="mt-2" style={{ fontFamily: "CFNotche-Bold" }}>
                    Register
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Centered Menu */}
          <div
            className="collapse navbar-collapse d-flex justify-content-center"
            id="navbarSupportedContent"
            style={{ zIndex: "1500", marginRight: "3rem" }}
          >
            <ul className="navbar-nav text-center mb-2 mb-lg-0">
              {data &&
                data.map(({ id, animationData, name, moverto }) => (
                  <BootstrapTooltip title={name} key={id}>
                    <li
                      className="nav-item"
                      onMouseEnter={() => handleMouseEnter(id)}
                      onMouseLeave={() => handleMouseLeave(id)}
                    >
                      <NavLink
                        className="nav-link"
                        activeclassname="activelink"
                        aria-current="page"
                        to={moverto}
                      >
                        <Lottie
                          animationData={animationData}
                          loop={!hoveredIcon === id}
                          isStopped={hoveredIcon !== id}
                          autoplay={hoveredIcon === id}
                          style={{ width: 35, height: 40 }}
                          id="screen33"
                        />
                      </NavLink>
                    </li>
                  </BootstrapTooltip>
                ))}
            </ul>
          </div>

          <div className="mobilenone">
            <div
              onClick={() =>
                handleGetStartedClick("https://hrm.datechmarketing.com/login")
              }
              className="btn p-2 px-1"
              style={{
                backgroundColor: "rgba(249, 252, 253, 0.23)",
                color: "#fff",
                width: "8rem",
              }}
              onMouseEnter={() => setisHover1(true)}
              onMouseLeave={() => setisHover1(false)}
            >
              <div className="d-flex">
                <Lottie
                  animationData={loginIcon}
                  loop={isHover1}
                  isStopped={!isHover1}
                  autoplay={isHover1}
                  style={{ width: 40, height: 40 }}
                />
                <p className="mx-1 m-2" style={{ fontFamily: "CFNotche-Bold" }}>
                  Login
                </p>
              </div>
            </div>
            <div
              onClick={() =>
                handleGetStartedClick(
                  "https://hrm.datechmarketing.com/client-signup"
                )
              }
              className="btn m-0"
              onMouseEnter={() => setisHover(true)}
              onMouseLeave={() => setisHover(false)}
              style={{ border: "black", height: "3.7rem" }}
            >
              <div className="d-flex">
                <Lottie
                  animationData={getStarted}
                  loop={!isHover}
                  isStopped={!isHover}
                  autoplay={isHover}
                  style={{ width: 70, height: 35 }}
                />
                <p className="mt-2" style={{ fontFamily: "CFNotche-Bold" }}>
                  Register
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
