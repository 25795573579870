import "../Services/service.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
// import required modules
import { FreeMode, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { GetStartedbtn } from "../Application/Getstartedbtn";
// import { HowtogetStart } from "./HowtogetStart";
import Lottie from "lottie-react";
import services from "../../Component/FILEJSON/services/services.json";
import customer from "../../Component/FILEJSON/services/custom.json";
import website from "../../Component/FILEJSON/services/website.json";
import mobileapp from "../../Component/FILEJSON/services/mobileapp.json";
import ebook from "../../Component/FILEJSON/services/ebook.json";
import googlelinkedin from "../../Component/FILEJSON/services/google linkedin youtube ads.json";
import support from "../../Component/FILEJSON/services/livechat.json";
import socialmedia from "../../Component/FILEJSON/services/smm.json";
import uiux from "../../Component/FILEJSON/services/home design.json";
import seo from "../../Component/FILEJSON/services/home marketing.json";
import maintenance from "../../Component/FILEJSON/services/support.json";
import back from "../../Component/FILEJSON/other pages and includes/Back.json";
import eCommercemanagement from "../../Component/FILEJSON/other pages and includes/ecommercemanagement.json";
import customPatches from "../../Component/FILEJSON/other pages and includes/custompatchesServices.json";
import privacy from "../../Component/FILEJSON/other pages and includes/Careers.json";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import axios from "axios";
import empverification from "../../Component/FILEJSON/other pages and includes/empverification.json";
import "./Job.css";
import getStarted from "../../Component/FILEJSON/other pages and includes/Getstarted.json";
import "../EVerification/EVerification.css";
import Modal from "./Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../Career/Career.css'
import "../FAQ/faq.css";
import '../Testimonials/Testimonials.css';
const servicesData = [
  {
    title: "Custom Development",
    animationData: customer,
    link: "/services/custom-development",
    description:
      "Robust custom development for web applications, CRM, ERP, Portals, eCommerce, Service Booking, HRM, Live Chat Box, Website Admin Panel, POS System or any backend.",
  },
  {
    title: "Website Design and Development",
    animationData: website,
    link: "/services/website-design-and-development",
    description:
      "Tailoring fully custom websites to your requirements, from UIUX mockup designs to frontend conversion, with an intuitive admin panel for seamless management on any or latest frameworks.",
  },
  {
    title: "Application Design Development",
    animationData: mobileapp,
    link: "/services/mobile-app-design-and-development",
    description:
      "UIUX mockups to functional screens, including an admin panel for effortless management. Creating fully custom mobile applications in Flutter, React Native, Ionic and Swift.",
  },
  {
    title: "UI/UX and Graphic Designing",
    animationData: uiux,
    link: "/services/graphic-designing",
    description:
      "Designing pixel-perfect website and app UIUX, logos, icons, vector art, stationary, business cards, and digital collaterals - all tailored to your unique requirements.",
  },
  {
    title: "SEO and ASO",
    animationData: seo,
    link: "/services/seo-and-aso",
    description:
      "Mastering On-page SEO, Off-page SEO, Local SEO, Technical SEO, and Mobile App SEO. Our experts craft the perfect strategy aligned with the search engine algorithms to increase organic traffic.",
  },
  {
    title: "Ads Management",
    animationData: googlelinkedin,
    link: "/services/ads-management",
    description:
      "Supercharge your business with targeted Google Ads and Social Media Ads Marketing, based on the targeted demographics. Elevate sales, leads, and paid traffic.",
  },
  {
    title: "Social Media Marketing",
    animationData: socialmedia,
    link: "/services/social-media-marketing",
    description:
      "Planning, designing, and executing monthly strategies by attracting buyers directly sharing posts in groups, and creating compelling blog posts to increase organic traffic.",
  },
  {
    title: "eCommerce Management",
    animationData: eCommercemanagement,
    link: "/services/eCommerce-management",
    description:
      "Our eCommerce Management Services include anything from Amazon Seller Accounts to Shopify Store Management. Let us handle your eCommerce operations on Amazon, eBay, Etsy, Walmart, and Shopify.",
  },
  {
    title: "Book and eBook Writing",
    animationData: ebook,
    link: "/services/book-&-eBook-writing",
    description:
      "Premium eBook writing services for Authors. Our expert writers turn your vision into a captivating book that elevates your brand.",
  },
  {
    title: "Maintenance and Support",
    animationData: maintenance,
    link: "/services/maintenance-and-support",
    description:
      "Providing ongoing maintenance and support services to keep your website or mobile application up to date, live, working perfectly and running.",
  },
  {
    title: "Live Chat Support and Sales",
    animationData: support,
    link: "/services/sales-and-support",
    description:
      "Your 24/7 Front Desk Officers - providing customer support to your traffic and engage them to generate sales and offer support based on the SOP’s.",
  },
  {
    title: "Promotional Branding",
    animationData: customPatches,
    link: "/services/promotional-branding",
    description:
      "Elevate your brand with our Promotional Branding services. Order custom patches, metal badges, and tailored apparel like t-shirts, hoodies, caps, and jackets. We offer a wide range of options to meet your specific branding needs.",
  },
];

function Job() {
  const [isHover, setisHover] = useState(false);
  const [isback, setisback] = useState(false);
  const [hoverStates, setHoverStates] = useState(
    Array(servicesData.length).fill(false)
  );
  const [hoverStatesmenu, setHoverStatesmenu] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [jobs, setJobs] = useState([]);
  const [expandedJobs, setExpandedJobs] = useState({});
  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [formData, setFormData] = useState({
    job_id: "",
    job_title: "",
    full_name: "",
    email: "",
    phone_no: "",
    cover_letter: "",
    document: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchJobs = async () => {
      console.log("Fetching jobs...");
      try {
        const response = await axios.get(
          "https://hrm.datechmarketing.com/api/all-jobs"
        );
        console.log("Raw API response:", response.data);

        // Map the jobs with numeric IDs starting from 1
        // const jobsWithIds = response.data.data.map((job, index) => ({
        //   ...job,
        //   id: index + 1, // Use numeric ID
        // }));

        // console.log("Processed jobs:", jobsWithIds);
        setJobs(response.data.data);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };
    fetchJobs();
  }, []);

  const toggleResponsibilities = (jobId) => {
    console.log("Toggling responsibilities for job:", jobId);
    setExpandedJobs((prev) => {
      const newState = {
        ...prev,
        [jobId]: !prev[jobId],
      };
      console.log("Updated expanded jobs state:", newState);
      return newState;
    });
  };

  const handleMouseMove = (e) => {
    setMousePosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleMouseEnter = (index) =>
    setHoverStates(hoverStates.map((state, i) => (i === index ? true : state)));
  const handleMouseLeave = () =>
    setHoverStates(Array(servicesData.length).fill(false));

  const handleMouseEnter1 = (id) => {
    setHoverStatesmenu(id);
  };

  const handleMouseLeave1 = () => {
    setHoverStatesmenu(null);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-arrow`]: {
      color: theme.palette.common.black,
    },
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "14px",
    },
  }));

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Service",
    url: "https://datechmarketing.com/services",
    name: "DA Tech Services",
    serviceType: [
      "Custom Development",
      "Website Design and Development",
      "Mobile App Design and Development",
      "SEO and ASO",
      "Maintenance and Support",
      "eCommerce Management",
      "Promotional Branding",
    ],
    description:
      "Explore our range of services, including custom software development, website design, eCommerce management, and more.",
    provider: {
      "@type": "Organization",
      name: "DA Tech",
      sameAs: "https://datechmarketing.com",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      reviewCount: "120",
    },
  };

  const handleApplyClick = (job) => {
    console.log("Job selected for application:", job);
    setSelectedJob(job);
    setFormData((prev) => {
      const newFormData = {
        ...prev,
        job_id: job.id, // Use numeric ID
        job_title: job.job_title,
      };
      console.log("Initial form data set:", newFormData);
      return newFormData;
    });
    setShowApplicationModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("Form field changed:", { field: name, value });
    setFormData((prev) => {
      const newFormData = { ...prev, [name]: value };
      console.log("Updated form data:", newFormData);
      return newFormData;
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("Document file selected:", {
      name: file?.name,
      type: file?.type,
      size: file?.size,
    });
    setFormData((prev) => {
      const newFormData = { ...prev, document: file };
      console.log("Updated form data with document:", {
        ...newFormData,
        document: file?.name,
      });
      return newFormData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      // Validate file type
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (formData.document && !allowedTypes.includes(formData.document.type)) {
        toast.error("Please upload a PDF or DOC/DOCX file");
        return;
      }

      // Validate file size (max 5MB)
      const maxSize = 5 * 1024 * 1024;
      if (formData.document && formData.document.size > maxSize) {
        toast.error("File size should be less than 5MB");
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append("job_id", selectedJob.id);
      formDataToSend.append("full_name", formData.full_name.trim());
      formDataToSend.append("email", formData.email.trim().toLowerCase());
      formDataToSend.append("phone_no", formData.phone_no.trim());
      formDataToSend.append("cover_letter", formData.cover_letter.trim());

      if (formData.document) {
        formDataToSend.append("document", formData.document);
      }

      const response = await axios.post(
        "https://hrm.datechmarketing.com/api/apply-job",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      );

      console.log(response);

      if (
        response.data.success ||
        response?.status === 201 ||
        response?.status === 200
      ) {
        // Reset form data
        setFormData({
          job_id: "",
          job_title: "",
          full_name: "",
          email: "",
          phone_no: "",
          cover_letter: "",
          document: null,
        });

        // Close modal
        setShowApplicationModal(false);

        // Show success toast
        toast.success(
          "Application submitted successfully! We'll get back to you soon."
        );
      }
    } catch (error) {
      console.error("Error details:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });

      if (error.response?.status === 500) {
        toast.error("Server error. Please try again later or contact support.");
      } else if (error.response?.status === 422) {
        const validationErrors = error.response.data.errors;
        let errorMessage = "Please correct the following:\n";
        Object.keys(validationErrors).forEach((field) => {
          errorMessage += `${field}: ${validationErrors[field].join(", ")}\n`;
        });
        toast.error(errorMessage);
      } else {
        toast.error("Error submitting application. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="row servicemargin">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <title>
          Hire an Award-winning IT company | Design, Development, Digital
          Marketing and Business Solutions | DA Tech
        </title>
        <meta
          name="description"
          content="Award-Winning IT Company: Design, Development, Digital Marketing and Business Solutions provider
near me. From creative design to robust developments and marketing with 24/7 live chat support, we
can handle everything."
        />
        <meta
          name="keywords"
          content="award-winning it company near me, development, design, digital marketing, business solutions"
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <div className="col-12">
        <div className="btnsergridcontainer mx-3 btnsgrid-jobs ">
          <div
            className="col-1"
            onMouseMove={handleMouseMove}
            onMouseEnter={() => setisback(true)}
            onMouseLeave={() => setisback(false)}
          >
            <BootstrapTooltip
              title="Previous Page"
              placement="bottom"
              PopperProps={{
                anchorEl: {
                  getBoundingClientRect: () => ({
                    top: mousePosition.y + 10, // Adjust tooltip position below the mouse
                    left: mousePosition.x - 40, // Adjust for horizontal centering
                    width: 0,
                    height: 0,
                  }),
                },
              }}
            >
              <Link to="/">
                <Lottie
                  animationData={back}
                  loop={isback}
                  isStopped={!isback}
                  autoplay={isback}
                  style={{ width: 40, height: 40 }}
                  id="backbtnport"
                />
              </Link>
            </BootstrapTooltip>
          </div>
          <div className="heading-mobile-jobs">
            {/*<h1 className="text-center subpagestitle mb-0">Jobs</h1> */}
            {/* <h1
              className="subpagestitle mb-0 mx-1 mt-1 mobilepara d-flex  justify-content-md-center justify-content-end"
              id="eightheight"
              onMouseEnter={() => setisHover(true)}
              onMouseLeave={() => setisHover(false)}
            >
              <Lottie
                animationData={privacy}
                loop={!isHover}
                isStopped={!isHover}
                autoplay={isHover}
                style={{ width: 30, height: 30 }}
                className="mt-2 privicon"
              />
              <span className="text-center subpagestitle mb-0 mt-2 priv-policy-heading-mob">
                Jobs
              </span>
            </h1> */}
            <div className="">
              <h1
                className="text-center emp-verification-mob-heading subpagestitle mb-0 d-flex justify-content-md-center justify-content-center"
                onMouseEnter={() => setisHover(true)}
                onMouseLeave={() => setisHover((currentValue) => !currentValue)}
              >
                <Lottie
                  animationData={privacy}
                  loop={!isHover}
                  isStopped={!isHover}
                  autoplay={isHover}
                  style={{ width: 40, height: 35, marginRight: '10px' }}
                  id="faqiconmain"
                  className="mt-0 mb-1 mailiconwidth desktop-icon-testimonial"
                />
                <span className="mt-1">Jobs <small style={{
                width:'30px', height:'60px', display: "inline-block",
     
              }}>
                <Lottie
                  animationData={privacy}
                  loop={!isHover}
                  isStopped={!isHover}
                  autoplay={isHover}
                  style={{ width: 40, height: 35 }}
                  id="faqiconmain icon-testimonial"
                  className="mt-0 mb-1 mailiconwidth mobile-icon-testimonial icon-testimonial contact-icon-mob"
                />
                </small></span>
              </h1>
            </div>
            <p
              className="text-center mb-0 paragraphmargin paragraphmargin-jobs"
              style={{ fontFamily: "CFNotche-Bold", marginRight:'1.5rem' }}
            >
              Join our team and be part of something great. Explore our current
              job openings below.
            </p>
          </div>
        </div>

        <div className="row mt-1 d-flex">
          <div className="jobs-div">
            {jobs.map((job, index) => (
              <div key={job.id} className="job-card mx-auto">
                <div className="job-header">
                  <div className="job-header-desk">
                    <h3>{job.job_title}</h3>
                    <button
                      className="apply-btn apply-btn-desktop"
                      onClick={() => handleApplyClick(job)}
                    >
                      Apply Now
                    </button>
                  </div>

                  <div className="job-meta">
                    <div className="job-meta-left">
                      <span className="employment-type">
                        {job.employment_type}
                      </span>
                      <span className="location">{job.job_location}</span>
                    </div>

                    <div className="job-meta-right">
                      <span className="apply-btn-container">
                        <button
                          style={{
                            display: "none",
                          }}
                          className="apply-btn mobile-apply-btn"
                          onClick={() => handleApplyClick(job)}
                        >
                          Apply Now
                        </button>
                      </span>
                    </div>

                    <div className="job-actions job-actions-desktop">
                      <button
                        className="toggle-btn"
                        onClick={() => toggleResponsibilities(job.id)}
                      >
                        {expandedJobs[job.id] ? (
                          <>
                            <IoIosArrowUp /> Hide Details
                          </>
                        ) : (
                          <>
                            <IoIosArrowDown /> Show Details
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="job-actions job-actions-mobile">
                  <button
                    className="toggle-btn"
                    onClick={() => toggleResponsibilities(job.id)}
                  >
                    {expandedJobs[job.id] ? (
                      <>
                        <IoIosArrowUp /> Hide Details
                      </>
                    ) : (
                      <>
                        <IoIosArrowDown /> Show Details
                      </>
                    )}
                  </button>
                </div>

                {expandedJobs[job.id] && (
                  <div
                    className="responsibilities"
                    dangerouslySetInnerHTML={{ __html: job.responsibilities }}
                  />
                )}
              </div>
            ))}
          </div>
          <div
            className={`fixedcontainer1  career-btns-div d-flex justify-content-center gap-md-4  fixedcontainer1mobile service-detail-buttons
            jobs-button-div
                        `}
            id="mobilecenter"
            style={{ width: "100%" }}
          >
            <Link
              to="/eVerification"
              className="buttons-service jobs-button button1"
            >
              <GetStartedbtn
                btnvalue="Employee Verification"
                iconbtn={empverification}
              />
            </Link>
            <Link
              to="https://hrm.datechmarketing.com/employee/signup"
              className=" buttons-service jobs-button button2"
            >
              <GetStartedbtn
                btnvalue="Didn’t find desired position"
                iconbtn={getStarted}
              />
            </Link>
          </div>
        </div>
      </div>
      {showApplicationModal && (
        <Modal
          showModal={showApplicationModal}
          closeModal={() => setShowApplicationModal(false)}
        >
          <div className="application-modal">
            <h2>Apply for {selectedJob?.job_title}</h2>
            <form onSubmit={handleSubmit} className="application-form">
              <div className="form-group">
                <label>Job Title</label>
                <input
                  type="text"
                  name="job_title"
                  value={formData.job_title}
                  readOnly
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Full Name *</label>
                <input
                  type="text"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Email *</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Phone Number *</label>
                <input
                  type="tel"
                  name="phone_no"
                  value={formData.phone_no}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Cover Letter *</label>
                <textarea
                  name="cover_letter"
                  value={formData.cover_letter}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                  rows="4"
                />
              </div>

              <div className="form-group">
                <label>Resume/CV * (PDF or DOC/DOCX, max 5MB)</label>
                <input
                  type="file"
                  name="document"
                  onChange={handleFileChange}
                  required
                  accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  className="form-control"
                />
              </div>

              <button
                type="submit"
                className="submit-btn"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit Application"}
              </button>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Job;
