import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import about from "../FILEJSON/other pages and includes/about.json";
import blogs from "../FILEJSON/other pages and includes/blogs.json";
import careers from "../FILEJSON/other pages and includes/Careers.json";
import clientlogin from "../FILEJSON/other pages and includes/Login.json";
import contact from "../FILEJSON/other pages and includes/Contactpage.json";
import emplogin from "../FILEJSON/other pages and includes/Getstarted.json";
import empverification from "../FILEJSON/other pages and includes/empverification.json";
import faq from "../FILEJSON/other pages and includes/faq.json";
import privacy from "../FILEJSON/other pages and includes/privacy-policy.json";
import testimonial from "../FILEJSON/other pages and includes/testimonials.json";
import portfolio from "../FILEJSON/portfolio/portfolio.json";
import service from "../FILEJSON/services/services.json";
import tc from "../FILEJSON/other pages and includes/t&c's.json";
import application from "../FILEJSON/other pages and includes/applications.json";
import loginIcon from "../FILEJSON/navbar/loginiconnavbar.json";
import { Menu } from "@mui/material";
import logo from "../FILEJSON/logoicon.png";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import Email from "../FILEJSON/Footer/Email.json";
import Call from "../FILEJSON/Footer/Call.json";
import Skype from "../FILEJSON/Footer/Skype.json";
import Whatsapp from "../FILEJSON/Footer/Whatsapp.json";
import Linkedin from "../FILEJSON/Footer/Linkedin.json";
import Twitter from "../FILEJSON/Footer/Twitter.json";
import Facebook from "../FILEJSON/Footer/Facebook.json";
import Instagram from "../FILEJSON/Footer/Instagram.json";
import TikTok from "../FILEJSON/Footer/Tiktok.json";

const variants = {
  open: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
      background: { duration: 0.2 },
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      background: { duration: 0.3 },
    },
  },
};

export const Navigation = ({ isOpen, toggle }) => {
  const [height, setHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [installPrompt, setInstallPrompt] = useState(null);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [animationStates, setAnimationStates] = useState({});
  const emailRef = useRef(null);
  const whatsappRef = useRef(null);
  const skypeRef = useRef(null);
  const callRef = useRef(null);
  const linkedinRef = useRef(null);
  const twitterRef = useRef(null);
  const facebookRef = useRef(null);
  const instagramRef = useRef(null);
  const tiktokRef = useRef(null);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobileScreen(window.innerWidth <= 500);
    };

    // Check initially
    checkIfMobile();

    // Add event listener for window resize
    window.addEventListener("resize", checkIfMobile);

    // Cleanup
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  // useEffect(() => {
  //   function handleResize() {
  //     const screenHeight = window.innerHeight;
  //     const screenWidth = window.innerWidth;
  //     const newHeight =
  //       screenWidth <= 475 ? screenHeight - 120 : screenHeight - 88;
  //     setHeight(newHeight);

  //     // Detect mobile devices
  //     setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent));
  //   }

  //   handleResize();
  //   window.addEventListener("resize", handleResize);

  //   // Listen for PWA installation prompt
  //   window.addEventListener("beforeinstallprompt", (e) => {
  //     e.preventDefault();
  //     setInstallPrompt(e); // Save the event for triggering later
  //   });

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // const handleInstallClick = () => {
  //   if (installPrompt) {
  //     installPrompt.prompt(); // Trigger the PWA installation
  //     installPrompt.userChoice.then((choiceResult) => {
  //       if (choiceResult.outcome === "accepted") {
  //         console.log("User accepted the installation");
  //       } else {
  //         console.log("User dismissed the installation");
  //       }
  //       setInstallPrompt(null); // Clear the prompt
  //     });
  //   }
  // };

  const handleMouseEnter = (ref) => {
    if (ref.current) {
      ref.current.goToAndPlay(0);
    }
  };

  const handleMouseLeave = (ref) => {
    if (ref.current) {
      ref.current.goToAndStop(0);
    }
  };

  return (
    <motion.ul
      variants={variants}
      className={isOpen ? "bgblack" : "bgblacknone"}
      style={{ height: `${height}px`, position: "relative" }}
    >
      {/* {isMobile && (
        <MenuItem
          i={3}
          key={"download"}
          name={"Download App"}
          icon={logo} // Replace with your icon if needed
          isOpen={isOpen}
          toggle={toggle}
          onClick={handleInstallClick}
        />
      )} */}

      {itemIds.map(({ id, name, icon, linkto, animationData }) => (
        <MenuItem
          i={id}
          key={id}
          name={name}
          icon={icon}
          linkto={linkto}
          animationData={animationData ? animationData : false}
          isOpen={isOpen}
          toggle={toggle}
        />
      ))}

      {/* Social Icons Section - Only show on mobile */}
      {isMobileScreen && (
        <div
          style={{
            padding: "0 20px",
          }}
        >
          {/* Contact Icons Row */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "25px",
              marginBottom: "15px",
            }}
          >
            {/* <Link to="mailto:info@thedatech.com" target="_blank">
              <div
                style={{ width: 30, height: 30 }}
                onMouseEnter={() => setHoveredIcon("email")}
                onMouseLeave={() => setHoveredIcon(null)}
              >
                <Lottie
                  animationData={Email}
                  loop={hoveredIcon === "email"}
                  autoplay={hoveredIcon === "email"}
                />
              </div>
            </Link> */}

            <Link to="mailto:info@thedatech.com" target="_blank">
              <div
                style={{ width: 30, height: 30 }}
                onMouseEnter={() => handleMouseEnter(emailRef)}
                onMouseLeave={() => handleMouseLeave(emailRef)}
              >
                <Lottie
                  lottieRef={emailRef}
                  animationData={Email}
                  loop={false}
                  autoplay={false}
                />
              </div>
            </Link>

            <Link to="https://wa.me/923048880004" target="_blank">
              <div
                style={{ width: 30, height: 30 }}
                onMouseEnter={() => handleMouseEnter(whatsappRef)}
                onMouseLeave={() => handleMouseLeave(whatsappRef)}
              >
                <Lottie
                  lottieRef={whatsappRef}
                  animationData={Whatsapp}
                  loop={false}
                  autoplay={false}
                />
              </div>
            </Link>
            <Link
              to="https://join.skype.com/invite/wHiwSnCgLZYj"
              target="_blank"
            >
              <div
                style={{ width: 30, height: 30 }}
                onMouseEnter={() => handleMouseEnter(skypeRef)}
                onMouseLeave={() => handleMouseLeave(skypeRef)}
              >
                <Lottie
                  lottieRef={skypeRef}
                  animationData={Skype}
                  loop={false}
                  autoplay={false}
                />
              </div>
            </Link>
            <Link to="tel:+923048880004" target="_blank">
              <div
                style={{ width: 30, height: 30 }}
                onMouseEnter={() => handleMouseEnter(callRef)}
                onMouseLeave={() => handleMouseLeave(callRef)}
              >
                <Lottie
                  lottieRef={callRef}
                  animationData={Call}
                  loop={false}
                  autoplay={false}
                />
              </div>
            </Link>
          </div>

          {/* Social Media Icons Row */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            {/* LinkedIn */}
            <Link
              to="https://www.linkedin.com/company/thedatech"
              target="_blank"
            >
              <div
                style={{
                  width: 35,
                  height: 35,
                  border: "2px solid #0077B5",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }}
                onMouseEnter={() => handleMouseEnter(linkedinRef)}
                onMouseLeave={() => handleMouseLeave(linkedinRef)}
              >
                <div style={{ width: 25, height: 25 }}>
                  <Lottie
                    lottieRef={linkedinRef}
                    animationData={Linkedin}
                    loop={false}
                    autoplay={false}
                  />
                </div>
              </div>
            </Link>

            {/* Twitter */}
            <Link to="https://twitter.com/thedatech" target="_blank">
              <div
                style={{
                  width: 35,
                  height: 35,
                  border: "2px solid #1DA1F2",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }}
                onMouseEnter={() => handleMouseEnter(twitterRef)}
                onMouseLeave={() => handleMouseLeave(twitterRef)}
              >
                <div style={{ width: 25, height: 25 }}>
                  <Lottie
                    lottieRef={twitterRef}
                    animationData={Twitter}
                    loop={false}
                    autoplay={false}
                  />
                </div>
              </div>
            </Link>

            {/* Facebook */}
            <Link to="https://www.facebook.com/thedatech" target="_blank">
              <div
                style={{
                  width: 35,
                  height: 35,
                  border: "2px solid #4267B2",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }}
                onMouseEnter={() => handleMouseEnter(facebookRef)}
                onMouseLeave={() => handleMouseLeave(facebookRef)}
              >
                <div style={{ width: 25, height: 25 }}>
                  <Lottie
                    lottieRef={facebookRef}
                    animationData={Facebook}
                    loop={false}
                    autoplay={false}
                  />
                </div>
              </div>
            </Link>

            {/* Instagram */}
            <Link to="https://www.instagram.com/the_datech/" target="_blank">
              <div
                style={{
                  width: 35,
                  height: 35,
                  border: "2px solid #E1306C",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }}
                onMouseEnter={() => handleMouseEnter(instagramRef)}
                onMouseLeave={() => handleMouseLeave(instagramRef)}
              >
                <div style={{ width: 25, height: 25 }}>
                  <Lottie
                    lottieRef={instagramRef}
                    animationData={Instagram}
                    loop={false}
                    autoplay={false}
                  />
                </div>
              </div>
            </Link>

            {/* TikTok */}
            <Link to="https://www.tiktok.com/@da.tech" target="_blank">
              <div
                style={{
                  width: 35,
                  height: 35,
                  border: "2px solid #69C9D0",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }}
                onMouseEnter={() => handleMouseEnter(tiktokRef)}
                onMouseLeave={() => handleMouseLeave(tiktokRef)}
              >
                <div style={{ width: 25, height: 25 }}>
                  <Lottie
                    lottieRef={tiktokRef}
                    animationData={TikTok}
                    loop={false}
                    autoplay={false}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      )}
    </motion.ul>
  );
};

const itemIds = [
  {
    id: 1,
    name: "Login",
    linkto: "https://hrm.datechmarketing.com/login",
    animationData: loginIcon,
  },
  {
    id: 2,
    name: "Register",
    linkto: "https://hrm.datechmarketing.com/client-signup",
    animationData: emplogin,
  },
  { id: 3, name: "Services", linkto: "/services", animationData: service },
  {
    id: 4,
    name: "Solutions",
    linkto: "/solutions",
    animationData: application,
  },
  { id: 5, name: "Portfolios", linkto: "/portfolio", animationData: portfolio },
  { id: 6, name: "About", linkto: "/about", animationData: about },
  {
    id: 7,
    name: "Testimonials",
    linkto: "/testimonials",
    animationData: testimonial,
  },
  { id: 8, name: "Contact", linkto: "/contact", animationData: contact },
  { id: 9, name: "Blogs", linkto: "/blogs", animationData: blogs },
  { id: 10, name: "FAQ's", linkto: "/faq", animationData: faq },
  {
    id: 11,
    name: "Privacy Policy",
    linkto: "/privacy-policy",
    animationData: privacy,
  },
  {
    id: 12,
    name: "Terms & C's",
    linkto: "/terms-&-condition",
    animationData: tc,
  },
  {
    id: 13,
    name: "Employee Verification",
    linkto: "/eVerification",
    animationData: empverification,
  },
  {
    id: 14,
    name: "Careers",
    // linkto: "https://hrm.thedatech.com/employee/signup",
    linkto: "/jobs",
    animationData: careers,
  },
];
