import UseAnimations from "react-useanimations";
import facebook from "react-useanimations/lib/facebook";
import instagram from "react-useanimations/lib/instagram";
import linkedin from "react-useanimations/lib/linkedin";
import Mail from "react-useanimations/lib/mail";
import { FiFacebook, FiInstagram, FiLinkedin, FiMail } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import contact from "../FILEJSON/other pages and includes/Contactpage.json";
import about from "../FILEJSON/other pages and includes/about.json";
import blogs from "../FILEJSON/other pages and includes/blogs.json";
import careers from "../FILEJSON/other pages and includes/Careers.json";
import faq from "../FILEJSON/other pages and includes/faq.json";
import testimonial from "../FILEJSON/other pages and includes/testimonials.json";
import Call from "../FILEJSON/Footer/footercall.json";
import Email from "../FILEJSON/Footer/Email.json";
import Skype from "../FILEJSON/Footer/Skype.json";
import Whatsapp from "../FILEJSON/Footer/Whatsapp.json";
import Linkedin from "../FILEJSON/Footer/Linkedin.json";
import Twitter from "../FILEJSON/Footer/Twitter.json";
import Facebook from "../FILEJSON/Footer/Facebook.json";
import Instagram from "../FILEJSON/Footer/Instagram.json";
import TikTok from "../FILEJSON/Footer/Tiktok.json";
import Lottie from "lottie-react";
import Threads from "../FILEJSON/Footer/footerthreads.json";
import Youtube from "../FILEJSON/Footer/footeryoutube.json";
import Meeting from '../FILEJSON/Footer/footerschedulemeeting.json';

export default function TopNavbar() {
  const [hovered, setHovered] = useState(false);
  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);
  const [hovered5, setHovered5] = useState(false);
  const [hovered6, setHovered6] = useState(false);
  const [hoveredmail, setHoveredmail] = useState(false);
  const [hoveredwhat, setHoveredmailwhat] = useState(false);
  const [hoveredphone, setHoveredmailphone] = useState(false);
  const [hoveredmeeting, setHoveredmeeting] = useState(false);

  const [hoveredskype, setHoveredskype] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  const [isVisible5, setIsVisible5] = useState(false);
  const [isVisible6, setIsVisible6] = useState(false);

  const handleMouseEntermail = () => {
    setHoveredmail(true);
  };

  const handleMouseLeavemail = () => {
    setHoveredmail(false);
  };


  const handleMouseEnterskype = () => {
    setHoveredskype(true);
  };

  const handleMouseLeaveskype = () => {
    setHoveredskype(false);
  };




  

  const handleMouseEnterMeeting = () => {
    setHoveredmeeting(true);
  };

  const handleMouseLeaveMeeting = () => {
    setHoveredmeeting(false);
  };

  const handleMouseEnterwhat = () => {
    setHoveredmailwhat(true);
  };

  const handleMouseLeavewhat = () => {
    setHoveredmailwhat(false);
  };
  const handleMouseEnterphone = () => {
    setHoveredmailphone(true);
  };

  const handleMouseLeavephone = () => {
    setHoveredmailphone(false);
  };
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const handleMouseEnter1 = () => {
    setHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setHovered1(false);
  };
  const handleMouseEnter2 = () => {
    setHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setHovered2(false);
  };
  const handleMouseEnter3 = () => {
    setHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setHovered3(false);
  };
  const handleMouseEnter4 = () => {
    setHovered4(true);
  };

  const handleMouseLeave4 = () => {
    setHovered4(false);
  };

  const handleMouseEnter5 = () => {
    setHovered5(true);
  };

  const handleMouseLeave5 = () => {
    setHovered5(false);
  };

  const handleMouseEnter6 = () => {
    setHovered6(true);
  };

  const handleMouseLeave6 = () => {
    setHovered6(false);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <div className="container-fluid bg-dark">
      <div className="topcontainer">
        <div className="col-3" style={{ textAlign: "left" }}>
          <div className="row mt-0" style={{ float: "left" }}>
            <div
              className="col"
              style={{ textAlign: "left" }}
              onMouseEnter={handleMouseEntermail}
              onMouseLeave={handleMouseLeavemail}
            >
              <BootstrapTooltip title="Email Now">
                <Link to="mailto:info@thedatech.com" target="__blank">
                  <div style={{ width: 25, height: 25 }}>
                    <Lottie
                      animationData={Email}
                      loop={!hoveredmail}
                      autoplay={hoveredmail}
                    />
                  </div>
                </Link>
              </BootstrapTooltip>
            </div>
            <div
              className="col"
              style={{ textAlign: "left" }}
              onMouseEnter={handleMouseEnterwhat}
              onMouseLeave={handleMouseLeavewhat}
            >
              <BootstrapTooltip title="Call Now">
                <Link to="tel:+923048880004" target="__blank">
                  <div style={{ width: 25, height: 25 }}>
                    <Lottie
                      animationData={Call}
                      loop={hoveredwhat}
                      autoplay={hoveredwhat}
                    />
                  </div>
                </Link>
              </BootstrapTooltip>
            </div>
            <div
              className="col"
              style={{ textAlign: "left" }}
              onMouseEnter={handleMouseEnterskype}
              onMouseLeave={handleMouseLeaveskype}
            >
              <BootstrapTooltip title="Skype">
                <Link
                  to="https://join.skype.com/invite/wHiwSnCgLZYj"
                  target="__blank"
                >
                  <div style={{ width: 25, height: 25 }}>
                    <Lottie
                      animationData={Skype}
                      loop={!hoveredskype}
                      autoplay={hoveredskype}
                    />
                  </div>
                </Link>
              </BootstrapTooltip>
            </div>
            <div
              className="col"
              style={{ textAlign: "left" }}
              onMouseEnter={handleMouseEnterphone}
              onMouseLeave={handleMouseLeavephone}
            >
              <BootstrapTooltip title="WhatsApp">
                <Link to="https://wa.me/923048880004" target="__blank">
                  <div style={{ width: 25, height: 25 }}>
                    <Lottie
                      animationData={Whatsapp}
                      loop={!hoveredphone}
                      autoplay={hoveredphone}
                    />
                  </div>
                </Link>
              </BootstrapTooltip>
            </div>

            <div
              className="col"
              style={{ textAlign: "left" }}
              onMouseEnter={handleMouseEnterMeeting}
              onMouseLeave={handleMouseLeaveMeeting}
            >
              <BootstrapTooltip title="Schedule Meeting">
                <Link to="https://calendar.app.google/cXw4yKSnPJzDehKV7" target="__blank">
                  <div style={{ width: 25, height: 25 }}>
                    <Lottie
                      animationData={Meeting}
                      loop={!hoveredmeeting}
                      autoplay={hoveredmeeting}
                    />
                  </div>
                </Link>
              </BootstrapTooltip>
            </div>
          </div>
        </div>
        <div className="col-6">
          <ul
            className="text-white fornav text-center"
            style={{ paddingLeft: "0" }}
          >
            <li
              className="p-1 px-4"
              style={{ borderRight: "2px solid #333131" }}
            >
              <Link
                to="/contact"
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
              >
                <BootstrapTooltip
                  title={
                    <div style={{ width: 28, height: 28 }}>
                      <Lottie
                        animationData={contact}
                        loop={!isVisible}
                        autoplay={isVisible}
                      />
                    </div>
                  }
                >
                  Contact
                </BootstrapTooltip>
              </Link>
            </li>
            <li
              className="p-1 px-4"
              style={{ borderRight: "2px solid #333131" }}
            >
              <Link
                to="/blogs"
                onMouseEnter={() => setIsVisible1(true)}
                onMouseLeave={() => setIsVisible1(false)}
              >
                <BootstrapTooltip
                  title={
                    <div style={{ width: 28, height: 28 }}>
                      <Lottie
                        animationData={blogs}
                        loop={!isVisible1}
                        autoplay={isVisible1}
                      />
                    </div>
                  }
                >
                  Blogs
                </BootstrapTooltip>
              </Link>
            </li>
            <li
              className="p-1 px-4"
              style={{ borderRight: "2px solid #333131" }}
            >
              <Link
                to="/testimonials"
                onMouseEnter={() => setIsVisible5(true)}
                onMouseLeave={() => setIsVisible5(false)}
              >
                <BootstrapTooltip
                  title={
                    <div style={{ width: 28, height: 28 }}>
                      <Lottie
                        animationData={testimonial}
                        loop={!isVisible5}
                        autoplay={isVisible5}
                      />
                    </div>
                  }
                >
                  Testimonials
                </BootstrapTooltip>
              </Link>
            </li>
            <li
              className="p-1 px-4"
              style={{ borderRight: "2px solid #333131" }}
            >
              <Link
                to="/about"
                onMouseEnter={() => setIsVisible2(true)}
                onMouseLeave={() => setIsVisible2(false)}
              >
                <BootstrapTooltip
                  title={
                    <div style={{ width: 28, height: 28 }}>
                      <Lottie
                        animationData={about}
                        loop={!isVisible2}
                        autoplay={isVisible2}
                      />
                    </div>
                  }
                >
                  About
                </BootstrapTooltip>
              </Link>
            </li>
            <li
              className="p-1 px-4"
              style={{ borderRight: "2px solid #333131" }}
            >
              <Link
                to="/jobs"
                // target="__blank"
                onMouseEnter={() => setIsVisible3(true)}
                onMouseLeave={() => setIsVisible3(false)}
              >
                <BootstrapTooltip
                  title={
                    <div style={{ width: 28, height: 28 }}>
                      <Lottie
                        animationData={careers}
                        loop={!isVisible3}
                        autoplay={isVisible3}
                      />
                    </div>
                  }
                >
                  Careers
                </BootstrapTooltip>
              </Link>
            </li>
            <li className="p-1 px-4">
              <Link
                to="/faq"
                onMouseEnter={() => setIsVisible4(true)}
                onMouseLeave={() => setIsVisible4(false)}
              >
                <BootstrapTooltip
                  title={
                    <div style={{ width: 28, height: 28 }}>
                      <Lottie
                        animationData={faq}
                        loop={!isVisible4}
                        autoplay={isVisible4}
                      />
                    </div>
                  }
                >
                  FAQ’s
                </BootstrapTooltip>
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-3" style={{ textAlign: "right" }}>
          <div className="row mt-0" style={{ float: "right" }}>
            <div
              className="col"
              style={{ textAlign: "right", marginRight: "" }}
              onMouseEnter={handleMouseEnter2}
              onMouseLeave={handleMouseLeave2}
            >
              <BootstrapTooltip title="LinkedIn">
                <Link
                  to="https://www.linkedin.com/company/datechmarketing/"
                  target="__blank"
                >
                  <div style={{ width: 25, height: 25 }}>
                    <Lottie
                      animationData={Linkedin}
                      loop={!hovered2}
                      autoplay={hovered2}
                    />
                  </div>
                </Link>
              </BootstrapTooltip>
            </div>
            <div
              className="col"
              style={{ textAlign: "right", marginRight: "" }}
              onMouseEnter={handleMouseEnter3}
              onMouseLeave={handleMouseLeave3}
            >
              <BootstrapTooltip title="Threads">
                <Link
                  to="https://www.threads.net/@datechmarketing"
                  target="__blank"
                >
                  <div style={{ width: 23, height: 23 }}>
                    <Lottie
                      animationData={Threads}
                      loop={!hovered3}
                      autoplay={hovered3}
                    />
                  </div>
                </Link>
              </BootstrapTooltip>
            </div>
            <div
              className="col"
              style={{ textAlign: "right", marginRight: "" }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <BootstrapTooltip title="Facebook">
                <Link
                  to="https://www.facebook.com/datechmarketing/"
                  target="__blank"
                >
                  <div style={{ width: 23, height: 23 }}>
                    <Lottie
                      animationData={Facebook}
                      loop={!hovered}
                      autoplay={hovered}
                    />
                  </div>
                </Link>
              </BootstrapTooltip>
            </div>
            <div
              className="col"
              style={{ textAlign: "right", marginRight: "" }}
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
            >
              <BootstrapTooltip title="Instagram">
                <Link
                  to="https://www.instagram.com/datechmarketing/"
                  target="__blank"
                >
                  <div style={{ width: 24, height: 24 }}>
                    <Lottie
                      animationData={Instagram}
                      loop={!hovered1}
                      autoplay={hovered1}
                    />
                  </div>
                </Link>
              </BootstrapTooltip>
            </div>

            <div
              className="col"
              style={{ textAlign: "right", marginRight: "" }}
              onMouseEnter={handleMouseEnter5}
              onMouseLeave={handleMouseLeave5}
            >
              <BootstrapTooltip title="TikTok">
                <Link
                  to="https://www.tiktok.com/@datechmarketing"
                  target="__blank"
                >
                  <div style={{ width: 24, height: 24 }}>
                    <Lottie
                      animationData={TikTok}
                      loop={!hovered5}
                      autoplay={hovered5}
                    />
                  </div>
                </Link>
              </BootstrapTooltip>
            </div>
            <div
              className="col"
              style={{ textAlign: "right", marginRight: "" }}
              onMouseEnter={handleMouseEnter6}
              onMouseLeave={handleMouseLeave6}
            >
              <BootstrapTooltip title="YouTube">
                <Link
                  to="https://www.youtube.com/@datechmarketing"
                  target="__blank"
                >
                  <div style={{ width: 23, height: 23 }}>
                    <Lottie
                      animationData={Youtube}
                      loop={!hovered6}
                      autoplay={hovered6}
                    />
                  </div>
                </Link>
              </BootstrapTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}