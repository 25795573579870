import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ContextMenu.css";

const ContextMenu = ({ x, y, onClose }) => {
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 550);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 550);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  const handleOptionClick = (path) => {
    navigate(path);
    onClose();
  };

  const handleExternalClick = (url) => {
    window.open(url, "_blank");
    onClose();
  };

  return (
    <div
      ref={menuRef}
      className="custom-context-menu"
      style={{
        top: y,
        left: x,
        transform: `translate(${x + 200 > window.innerWidth ? "-100%" : "0"}, ${
          y + 300 > window.innerHeight ? "-100%" : "0"
        })`,
      }}
    >
      <div
        className="menu-item"
        onClick={(e) => {
          e.stopPropagation();
          handleExternalClick("https://hrm.datechmarketing.com/login");
        }}
      >
        Login
      </div>
      <div
        className="menu-item"
        onClick={(e) => {
          e.stopPropagation();
          handleExternalClick("https://hrm.datechmarketing.com/client-signup");
        }}
      >
        Get Started
      </div>
      <div
        className="menu-item menu-item-with-submenu"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        Services
        <div className="submenu">
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/services/custom-development")}
          >
            Custom Development
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/services/website-design-and-development")}
          >
            Website Development
          </div>
          <div
            className="menu-item"
            onClick={() =>
              handleOptionClick("/services/mobile-app-design-and-development")
            }
          >
            Mobile App Development
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/services/graphic-designing")}
          >
            Designing
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/services/seo-and-aso")}
          >
            SEO
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/services/ads-management")}
          >
            Ads Management
          </div>
          <div
            className="menu-item"
            onClick={() =>
              handleOptionClick("/services/social-media-marketing")
            }
          >
            Social Media Marketing
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/services/eCommerce-management")}
          >
            eCommerce Management
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/services/book-&-eBook-writing")}
          >
            Book & eBook Writing
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/services/maintenance-and-support")}
          >
            Website Maintenance
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/services/sales-and-support")}
          >
            Live Chat Support
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/services/promotional-branding")}
          >
            Promotional Branding
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/services/account-reinstatement")}
          >
            Account Reinstatement
          </div>
        </div>
      </div>
      <div
        className="menu-item menu-item-with-submenu"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        Solutions
        <div className="submenu">
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/solutions/eCommerce-system")}
          >
            eCommerce System
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/solutions/service-booking-system")}
          >
            Service Booking
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/solutions/company-management-system")}
          >
            Company Management
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/solutions/website-management-system")}
          >
            Website Management
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/solutions/pos-system")}
          >
            POS System
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/solutions/recruiting-portal")}
          >
            Recruiting Portal
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/solutions/marketplace")}
          >
            Marketplace
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/solutions/live-chat-video-and-calling-web-app")}
          >
            Live Chat App
          </div>
          <div
            className="menu-item"
            onClick={() => handleOptionClick("/solutions/screen-sharing-with-live-chat-and-audio")}
          >
            Screen Sharing App
          </div>
        </div>
      </div>
      <div
        className="menu-item"
        onClick={(e) => {
          e.stopPropagation();
          handleOptionClick("/portfolio");
        }}
      >
        Portfolio
      </div>
      <div
        className="menu-item"
        onClick={(e) => {
          e.stopPropagation();
          handleOptionClick("/testimonials");
        }}
      >
        Testimonials
      </div>
      {/* <div
        className="menu-item"
        onClick={(e) => {
          e.stopPropagation();
          handleOptionClick("/blogs");
        }}
      >
        Blogs
      </div> */}
      <div
        className="menu-item"
        onClick={(e) => {
          e.stopPropagation();
          handleOptionClick("/contact");
        }}
      >
        Contact
      </div>
      <div
        className="menu-item"
        onClick={(e) => {
          e.stopPropagation();
          handleOptionClick("/about");
        }}
      >
        About
      </div>
      {/* <div
        className="menu-item"
        onClick={(e) => {
          e.stopPropagation();
          handleOptionClick("/social-media-marketing");
        }}
      >
        {isMobile
          ? "Social Media Marketing and Management"
          : "Strategic Social Media Marketing and Management"}
      </div> */}
      {/* <div
        className="menu-item"
        onClick={(e) => {
          e.stopPropagation();
          handleOptionClick("/jobs");
        }}
      >
        Careers
      </div> */}
    </div>
  );
};

export default ContextMenu;
